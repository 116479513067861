import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, inject, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { ProjetService } from '@services-candidat/projet.service';
import { StructureService } from '@services-candidat/structure.service';
import { UserService } from '@services-candidat/user.service';
import {
  PermissionUtils,
  ShowToastrService,
  SignalService,
  SubscriptionDestroyerComponent,
  SyntheseContractualisationStructure,
  Utilisateur,
} from '@shared-ui';
import { NgxPermissionsService } from 'ngx-permissions';
import { SidebarStateService } from '../../services/sidebar-state.service';
import { ProjetInfoDepensesSharedService } from './component/projet-info-depenses/projet-info-depenses-shared.service';

@Component({
  selector: 'app-projet-creation',
  templateUrl: './projet-creation.component.html',
  styleUrls: ['./projet-creation.component.scss'],
})
export class ProjetCreationComponent extends SubscriptionDestroyerComponent implements OnInit, OnDestroy {
  private destroyRef = inject(DestroyRef);

  private sideBarIsOpen: boolean;

  userCharged = false;
  projetCharged = false;
  aapCharged = false;
  structuresCharged = false;
  syntheseContractualisationCharged = false;

  isShowing = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private sidebarStateService: SidebarStateService,
    private userService: UserService,
    private aapService: AapService,
    private projetService: ProjetService,
    private structureService: StructureService,
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService,
    private sharedService: ProjetInfoDepensesSharedService,
    private signalService: SignalService
  ) {
    super();
    this.sideBarIsOpen = false;

    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        this.sidebarStateService.changeState(false);
        this.renderer.addClass(document.body, 'sidebar-closed');
      }
    });
  }

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'nav-collapsed');

    this.sidebarStateService.changeState(this.sideBarIsOpen);
    this.sidebarStateService.clickBehavior.subscribe(state => {
      this.sideBarIsOpen = state;
    });

    if (!this.sideBarIsOpen) {
      this.renderer.addClass(document.body, 'sidebar-closed');
    }

    const projetId = this.route.snapshot.parent.params.id;
    this.userService
      .getUserObservable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((utilisateur: Utilisateur) => {
        if (utilisateur !== null) {
          this.permissionsService.flushPermissions();
          this.permissionsService.addPermission(PermissionUtils.getProjetPermissions(utilisateur, projetId));
          this.userCharged = true;
        }
      });

    this.projetService
      .getProjetById(projetId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: projetResult => {
          this.projetService.setProjetObservable(projetResult.body);
          this.projetCharged = true;

          this.loadAap(projetResult.body.aap.id);
          this.loadStructures(projetResult.body.id);
          this.loadSyntheseContractualisation(projetResult.body.id);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });

    this.loadSidenavDepenses();
  }

  loadAap(aapId: string): void {
    this.aapService
      .getAapById(aapId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.aapCharged = true;
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  loadStructures(projectId: string): void {
    this.structureService
      .getStructuresByIdProject(projectId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: structuresResult => {
          this.signalService.setStructures(structuresResult.body);
          this.structureService.setStructuresListObservable(structuresResult.body);
          this.structuresCharged = true;
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  loadSyntheseContractualisation(projectId: string): void {
    this.structureService
      .getSyntheseContractualisationStructures(projectId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: SyntheseContractualisationStructure[]) => {
          if (response) {
            this.syntheseContractualisationCharged = true;
          }
        },
        error: err => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  close(): void {
    this.isShowing = false;
  }

  private loadSidenavDepenses(): void {
    this.sharedService
      .getIsShowing()
      .pipe(this.takeUntilDestroyed())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((show: boolean) => {
        this.isShowing = show;
      });
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'nav-collapsed');
    this.sideBarIsOpen = false;
    this.sidebarStateService.changeState(this.sideBarIsOpen);
    this.renderer.removeClass(document.body, 'sidebar-closed');
  }
}

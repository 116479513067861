import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { ProjetService } from '@services-candidat/projet.service';
import { StructureService } from '@services-candidat/structure.service';
import { InviteStructureModalComponent } from '@shared-candidat/components/modals/invite-structure-modal/invite-structure-modal.component';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import { Aap, EnumRoleStructure, EnumTypeDocument, EnumTypePartenaire, Projet, ShowToastrService, Structure } from '@shared-ui';
import { Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-projet-consortium',
  templateUrl: './projet-consortium.component.html',
  styleUrls: ['./projet-consortium.component.scss'],
})
export class ProjetConsortiumComponent implements OnInit, OnDestroy {
  projet: Projet = new Projet();
  structuresIncompletes: Structure[] = [];
  isChefFileExist = true;
  isMandatoryExist = false;
  isCandidatsCompleted = true;
  isStructureCompleted = true;
  structureDocValid = true;
  aap: Aap = new Aap();
  subscriptions: Subscription[] = [];

  structures: Structure[];
  structuresDeleted: Structure[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private aapService: AapService,
    private projetService: ProjetService,
    private structureService: StructureService,
    private showToastrService: ShowToastrService,
    private dialog: MatDialog,
    public sharedFunction: SharedFunction
  ) {}

  ngOnInit(): void {
    this.getProjet();
  }

  /*
   * Cette méthode récupère le projet si l'id est renseigné
   * */
  getProjet(): void {
    this.subscriptions.push(
      this.projetService
        .getProjetObservable()
        .pipe(
          concatMap(responseProjet => {
            if (responseProjet) {
              this.projet = responseProjet;
              this.formatStructure(this.projet);
              return this.aapService.loadAapSubject();
            }
          })
        )
        .subscribe({
          next: responseAap => {
            if (responseAap) {
              this.aap = responseAap.body;
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        })
    );
  }

  /*
   * Format la liste des structure
   * */
  formatStructure(projet: Projet): void {
    this.subscriptions.push(
      this.structureService.getStructuresByIdProject(projet.id).subscribe(response => {
        if (response) {
          this.structureService.setStructuresListObservable(response.body);
          this.structures = response.body.filter(structure => !structure.closed);
          this.structuresDeleted = response.body.filter(structure => structure.closed);
          this.isStructureCompleted = this.structures?.length !== 0;
          this.checkChefDeFile();
          this.checkExistingStructureMandatairesDoc();
        }
      })
    );
  }

  /*
   * Vérifie si les fiches candidats sont completes
   * */
  checkCandidats(): void {
    if (this.structures.length > 0) {
      this.structuresIncompletes = this.sharedFunction.checkIncompleteCompanies(this.structures, this.projet, this.aap);
    }

    // TODO Décommenter pour activer les tuiles jaunes
    // this.isCandidatsCompleted = !(this.structuresIncompletes.length > 0);
  }

  /**
   * Ajouter une structure de type mandataire aux structures incompletes si aucun documents n'est trouvé
   */
  checkExistingStructureMandatairesDoc(): void {
    for (const structure of this.structures) {
      if (structure.role === EnumRoleStructure.MANDATAIRE) {
        this.subscriptions.push(
          this.projetService.getDocumentsStructure(this.projet.id, structure.id, [EnumTypeDocument.STRUCTURE]).subscribe((resp: any) => {
            const listDocumentStructure = resp.body;
            if (listDocumentStructure.length === 0) {
              this.structureDocValid = false;
              if (!this.structuresIncompletes.includes(structure)) {
                this.structuresIncompletes.push(structure);
              }
            }
          })
        );
      }
    }
  }
  /*
   * Verifie s'il y a un chef de file parmi les structures
   * */
  checkChefDeFile(): void {
    if (this.structures.length > 0) {
      this.isChefFileExist = this.structures.some(structure => structure.role === EnumRoleStructure.CHEF_DE_FILE);
      this.isMandatoryExist = this.structures.some(structure => structure.role === EnumRoleStructure.MANDATAIRE);

      if (this.isChefFileExist) {
        this.checkCandidats();
      }
    }
  }

  isStructureInvalid(structure: Structure): boolean {
    return this.sharedFunction.checkIncompleteCompany(structure, this.projet, this.aap);
  }

  goToInfoStructure(structure: Structure): void {
    this.router.navigate(['../projet-consortium-info/', structure.id], { relativeTo: this.route });
  }

  /*
   * Affiche la modale pour inviter une structure
   * */
  onInviteStructure(): void {
    if (this.sharedFunction.isProjectUpdatable(this.aap, this.projet)) {
      let contactsProjetList = [];
      this.structures.forEach(structure => {
        contactsProjetList = contactsProjetList.concat(structure.contacts);
      });
      const dialogRef = this.dialog.open(InviteStructureModalComponent, {
        data: {
          title: 'Inviter une structure à rejoindre le consortium',
          projet: this.projet,
          aap: this.aap,
          structures: this.structures,
          isChefFileExist: this.isChefFileExist,
          isMandatoryExist: this.isMandatoryExist,
          projetId: this.route.snapshot.parent.params.id,
          listContacts: contactsProjetList,
          textGoButton: 'Inviter',
          textReturnButton: 'Annuler',
        },
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          // mettre à jour le projet
          this.getProjet();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }

  protected readonly EnumRoleStructure = EnumRoleStructure;
  protected readonly EnumTypePartenaire = EnumTypePartenaire;
}

<!-- Entête de la page -->
<header class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <h2 *ngIf="projet.partenaireType === EnumTypePartenaire.MULTI" class="page-header__title">Consortium</h2>
  <h2 *ngIf="projet.partenaireType === EnumTypePartenaire.INDIV" class="page-header__title">Structure</h2>
  <div class="page-header__action" *ngxPermissionsOnly="['CONSORTIUM_WRITE']">
    <!-- ATTENTION : bouton dupliqué en bas de page (pour mobiles) -->
    <button
      id="go-creation-candidat"
      role="button"
      class="ds-btn ds-btn--primary"
      *ngIf="projet.partenaireType === EnumTypePartenaire.MULTI"
      [disabled]="!sharedFunction.isProjectUpdatable(aap, projet)"
      (click)="onInviteStructure()"
    >
      <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline" aria-hidden="true"></lib-pxl-icon>
      Inviter une structure
    </button>
    <button
      id="go-creation-candidat-indiv"
      role="button"
      class="ds-btn ds-btn--primary"
      *ngIf="projet.partenaireType === EnumTypePartenaire.INDIV"
      [disabled]="!sharedFunction.isProjectUpdatable(aap, projet) || (isChefFileExist && isMandatoryExist)"
      (click)="onInviteStructure()"
    >
      <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline" aria-hidden="true"></lib-pxl-icon>
      <span *ngIf="isChefFileExist && !isMandatoryExist">Inviter un mandataire</span>
      <span *ngIf="!isChefFileExist || (isChefFileExist && isMandatoryExist)">Inviter une structure</span>
    </button>
  </div>
</header>
<!-- FIN de Entête de la page -->

<!-- Contenu de la page -->
<div class="page-content">
  <!-- Alertes si les candidats ne sont pas tous complétés -->
  <ng-container *ngIf="!isCandidatsCompleted && sharedFunction.isProjectUpdatable(aap, projet)">
    <div class="card card--consortium-alert" *ngFor="let structure of structuresIncompletes; index as i">
      <div id="structure-incomplete" class="card__message" (click)="goToInfoStructure(structure)">
        Vous devez compléter la fiche de « {{ structure.raisonSocial }} » .
      </div>

      <lib-pxl-icon class="card__arrow" icon="icon-arrow-arrow-forward-ios" aria-hidden="true"></lib-pxl-icon>
    </div>
  </ng-container>

  <!-- Alerte si le chef de file n'existe pas  -->
  <div
    id="if-chef-de-file"
    class="card card--consortium-alert"
    *ngIf="!isChefFileExist && sharedFunction.isProjectUpdatable(aap, projet)"
    (click)="onInviteStructure()"
  >
    <div class="card__message">
      La structure du consortium est incomplète car il manque le Chef de file. Veuillez sélectionner ce rôle pour l'une des structures du
      consortium (section "Informations du siège/ de la structure")
    </div>
    <lib-pxl-icon class="card__arrow" icon="icon-arrow-arrow-forward-ios" aria-hidden="true"></lib-pxl-icon>
  </div>

  <!-- Alerte si aucune structure n'a été déclarée  -->
  <div
    id="if-structure-complete"
    class="card card--consortium-alert"
    (click)="onInviteStructure()"
    *ngIf="!isStructureCompleted && sharedFunction.isProjectUpdatable(aap, projet)"
  >
    <div class="card__message">Vous devez déclarer une structure pour ce projet.</div>
    <lib-pxl-icon class="card__arrow" icon="icon-arrow-arrow-forward-ios" aria-hidden="true"></lib-pxl-icon>
  </div>

  <!-- Liste des structures -->
  <div id="list-structure" class="structures">
    <ng-container *ngFor="let structure of structures; index as i">
      <!-- Carte 'contact' -->
      <div
        id="go-info-structure"
        class="card card--contact"
        [ngClass]="{ 'is-invalid': isStructureInvalid(structure) }"
        (click)="goToInfoStructure(structure)"
      >
        <h4 id="raison-social" class="card__title">
          {{ structure.raisonSocial }}
          <div
            id="role-structure"
            class="card__tag"
            *ngIf="structure.role === EnumRoleStructure.CHEF_DE_FILE || structure.role === EnumRoleStructure.MANDATAIRE"
            [class.card__tag--red]="structure.role === EnumRoleStructure.CHEF_DE_FILE"
            [class.card__tag--green]="structure.role === EnumRoleStructure.MANDATAIRE"
          >
            {{ EnumRoleStructure.toString(structure.role) }}
          </div>
        </h4>

        <lib-pxl-icon class="card__arrow" icon="icon-arrow-arrow-forward-ios" aria-hidden="true"></lib-pxl-icon>
      </div>
      <!-- FIN de Carte 'contact' -->
    </ng-container>
  </div>

  <!-- Liste des structures -->
  <div id="list-structure-deleted" class="structures" *ngIf="structuresDeleted.length > 0">
    <p class="title-structure-deleted">Structure(s) ayant quitté le consortium</p>
    <ng-container *ngFor="let structure of structuresDeleted; index as i">
      <!-- Carte 'contact' -->
      <div id="go-info-structure-deleted" class="card card--contact" (click)="goToInfoStructure(structure)">
        <h4 id="raison-social-deleted" class="card__title">
          {{ structure.raisonSocial }}
          <div
            id="role-structure"
            class="card__tag"
            *ngIf="structure.role === EnumRoleStructure.CHEF_DE_FILE || structure.role === EnumRoleStructure.MANDATAIRE"
            [class.card__tag--red]="structure.role === EnumRoleStructure.CHEF_DE_FILE"
            [class.card__tag--green]="structure.role === EnumRoleStructure.MANDATAIRE"
          >
            {{ EnumRoleStructure.toString(structure.role) }}
          </div>
        </h4>

        <lib-pxl-icon class="card__arrow" icon="icon-arrow-arrow-forward-ios" aria-hidden="true"></lib-pxl-icon>
      </div>
      <!-- FIN de Carte 'contact' -->
    </ng-container>
  </div>

  <!-- Actions de bas de page sur mobile -->
  <ng-container *ngxPermissionsOnly="['CONSORTIUM_WRITE']">
    <button
      id="go-creation-candidat-mobile"
      role="button"
      class="footer-action footer-action--cta ds-btn ds-btn--primary"
      *ngIf="projet.partenaireType === EnumTypePartenaire.MULTI"
      [disabled]="!sharedFunction.isProjectUpdatable(aap, projet)"
      (click)="onInviteStructure()"
    >
      <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline" aria-hidden="true"></lib-pxl-icon>
      Inviter une structure
    </button>
    <button
      id="go-creation-candidat-mobile-indiv"
      role="button"
      class="footer-action footer-action--cta ds-btn ds-btn--primary"
      *ngIf="projet.partenaireType === EnumTypePartenaire.INDIV"
      [disabled]="!sharedFunction.isProjectUpdatable(aap, projet) || (isChefFileExist && isMandatoryExist)"
      (click)="onInviteStructure()"
    >
      <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline" aria-hidden="true"></lib-pxl-icon>
      <span *ngIf="isChefFileExist && !isMandatoryExist">Inviter un mandataire</span>
      <span *ngIf="!isChefFileExist || (isChefFileExist && isMandatoryExist)">Inviter une structure</span>
    </button>
  </ng-container>
</div>
<!-- FIN de Contenu de la page -->

import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { SharedUiModule } from '@shared-ui';
export interface ModalDataAnneeCumul {
  title: string;
  years: number[];
  textGoButton: string;
  textReturnButton: string;
}
@Component({
  selector: 'lib-annee-cumul',
  standalone: true,
  imports: [CommonModule, MatTooltip, ReactiveFormsModule, MatDialogModule, SharedUiModule],
  templateUrl: './annee-cumul.component.html',
  styleUrl: './annee-cumul.component.scss',
})
export class AnneeCumulComponent implements OnInit {
  inputForm!: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<AnneeCumulComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalDataAnneeCumul,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog
  ) {}

  cancelModal() {
    if (this.inputForm.dirty) {
      const cn = confirm('Les modifications apportées ne seront peut-être pas sauvegardées. Etes-vous sûr de vouloir quitter cette page ?');
      if (cn) {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }

  saveModal() {
    if (this.inputForm.valid) {
      this.dialogRef.close(this.inputForm.getRawValue());
    } else {
      this.inputForm.markAllAsTouched();
    }
  }

  ngOnInit(): void {
    this.createForm();
  }

  private createForm() {
    this.inputForm = this.formBuilder.group({
      years: [null, Validators.required],
      soldeTresorerieDernierBilan: [null, Validators.required],
    });
  }

  protected readonly Number = Number;
}

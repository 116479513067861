<!-- Entête de la page -->
<header id="header-structure" class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <a class="page-header__back" (click)="onGoToConsortiumInfo()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Ma Structure
  </a>
  <h2 class="page-header__title">Génération de la fiche de demande d’aide</h2>
</header>
<!-- FIN de Entête de la page -->

<!-- Contenu de la page -->
<div class="page-content">
  <lib-card-info
    [color]="'blue'"
    *ngIf="isProcedureOngoingOrDone"
    [message]="'Une procédure de signature est en cours ou terminée. Vous ne pouvez pas éditer le contenu de la fiche de demande d\'aide.'"
  ></lib-card-info>

  <div class="card--info card--blue card--form">
    <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
    Veuillez compléter les informations ci-dessous et générer le document pour signature. Pour information ce document reprend également des
    données des sections “Budget”, “Représentant.e légal.e”, “Informations du siège / de la structure”, “Lieu de réalisation” et
    “Informations générales ; merci de vérifier que le document généré est complet avant de le signer et de le charger sur la plateforme. En
    cas de changement d’une donnée post génération il est nécessaire de générer de nouveau le document, le signer et le charger de nouveau
    sur la plateforme.
  </div>

  <form [formGroup]="ficheDemandeAideForm" class="form">
    <section class="page-section">
      <h2 class="page-section__title mt-3">Accès à la fiche de demande d'aide</h2>
      <div class="card--info card--blue">
        <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
        La fiche de demande d’aide contient des données confidentielles. Les personnes qui peuvent consulter les informations sont tous les
        contacts sélectionnés dans la liste ci-dessous. L’accès peut être ajouté ou révoqué par n’importe quel contact ayant accès.
      </div>
      <div class="card">
        <div
          class="ds-input-group"
          [class.ds-input-group--error]="
            !ficheDemandeAideForm.controls['contactsAutorisesMatricules']?.valid &&
            ficheDemandeAideForm.controls['contactsAutorisesMatricules']?.touched
          "
        >
          <label for="liste_contacts" class="ds-input-group__label fw-bold">
            Contact(s) devant avoir accès à la fiche de demande d’aide <lib-asterisk></lib-asterisk>
          </label>

          <div
            class="ds-select ds-select-group"
            [class.ds-select--erreur]="
              !ficheDemandeAideForm.controls['contactsAutorisesMatricules']?.valid &&
              ficheDemandeAideForm.controls['contactsAutorisesMatricules']?.touched
            "
            *ngIf="isDataLoaded"
          >
            <lib-ds-multiselect
              #contactsComponent
              [required]="false"
              class="filter"
              [dataList]="dataListContacts"
              [selectOptions]="{ placeholder: 'Sélectionner', isDisabled: isProcedureOngoingOrDone }"
              [name]="'liste_contacts'"
              (click)="selectContacts()"
            >
            </lib-ds-multiselect>
          </div>
          <ng-container
            *ngIf="
              ficheDemandeAideForm.get('contactsAutorisesMatricules')?.hasError('required') &&
              ficheDemandeAideForm.controls['contactsAutorisesMatricules']?.touched
            "
          >
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
          </ng-container>
          <ng-container
            *ngIf="
              ficheDemandeAideForm.get('contactsAutorisesMatricules')?.hasError('uncheckedUser') &&
              ficheDemandeAideForm.controls['contactsAutorisesMatricules']?.touched
            "
          >
            <div class="ds-input-group__feedback">
              Vous ne pouvez pas vous décocher vous-même de la liste des contacts. Merci de vous assurer que votre nom est bien coché pour
              pouvoir enregistrer ou générer la fiche de demande d’aide.
            </div>
          </ng-container>
        </div>
      </div>

      <lib-fiche-demande-aide-signature-manuelle
        [signatureManuelleForm]="ficheDemandeAideForm?.get('correspondantSignatureManuelle')"
        [etapeProjet]="etapeProjet"
        identiteFormName="identite"
      >
      </lib-fiche-demande-aide-signature-manuelle>

      <lib-fiche-demande-aide-signature-electronique
        [signatureElectroniqueForm]="ficheDemandeAideForm?.get('correspondantSignatureElectronique')"
        [etapeProjet]="etapeProjet"
        identiteFormName="identite"
      >
      </lib-fiche-demande-aide-signature-electronique>
    </section>

    <section class="page-section" *ngIf="isStructureCanAddActionnaires && isDataLoaded">
      <h2 class="page-section__title mt-3">Table de capitalisation simplifiée de la structure</h2>
      <lib-fiche-demande-aide-actionnaires
        [ficheDemandeAide]="ficheDemandeAide"
        [ficheDemandeAideActionnairesForm]="ficheDemandeAideForm.get('actionnairesGroup')"
        [disabled]="isProcedureOngoingOrDone"
      >
      </lib-fiche-demande-aide-actionnaires>
    </section>
    <section class="page-section" *ngIf="isDataLoaded">
      <h2 class="page-section__title mt-3">Liste des aides et demandes d'aides</h2>
      <lib-fiche-demande-aide-liste-aides
        [ficheDemandeAide]="ficheDemandeAide"
        [ficheDemandeAideListeAidesForm]="ficheDemandeAideForm.get('aidesGroup')"
      >
      </lib-fiche-demande-aide-liste-aides>
    </section>

    <section class="form-actions">
      <ng-container>
        <button
          type="submit"
          id="submit-1"
          value="Submit 1"
          class="ds-btn ds-btn--primary"
          [disabled]="ficheDemandeAideForm.disabled || isProcedureOngoingOrDone"
          (click)="saveAndGenerateFicheDemandeAide(ficheDemandeAideForm.value, true)"
        >
          <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
          Enregistrer
        </button>
      </ng-container>

      <ng-container>
        <button
          type="submit"
          id="submit-2"
          value="Submit 2"
          class="ds-btn ds-btn--primary"
          [disabled]="ficheDemandeAideForm.disabled || isProcedureOngoingOrDone"
          (click)="validFicheDemandeAide(ficheDemandeAideForm.value)"
        >
          <lib-pxl-icon class="icon" icon="icon-action-send"></lib-pxl-icon>
          Générer le document
        </button>
      </ng-container>
    </section>
  </form>
</div>

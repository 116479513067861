import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments-candidat/environment';
import { Depenses, DocumentProjet, EnumValidation } from '@shared-ui';
import { Observable } from 'rxjs';

@Injectable()
export class DepensesService {
  constructor(private httpClient: HttpClient) {}

  getProjetExpensesStatus(projetId: string): Observable<HttpResponse<EnumValidation>> {
    return this.httpClient.get<any>(environment.apiUrl + 'depenses-projet/projets/' + projetId + '/statut', {
      observe: 'response',
    });
  }

  getExpensesByProjetId(projetId: string): Observable<HttpResponse<Depenses>> {
    return this.httpClient.get<any>(environment.apiUrl + 'depenses-projet/projets/' + projetId, {
      observe: 'response',
    });
  }

  updateExpensesStructures(expenses: Depenses): Observable<HttpResponse<Depenses>> {
    expenses?.structures?.informationsStructures.forEach(structure => delete structure['name']);
    if (expenses.id) {
      return this.httpClient.put<any>(
        environment.apiUrl + 'depenses-projet/' + expenses.id + '/structure',
        JSON.stringify(expenses.structures),
        {
          observe: 'response',
        }
      );
    } else {
      return this.createExpenses(expenses);
    }
  }

  updateExpensesCalendar(expenses: Depenses): Observable<HttpResponse<Depenses>> {
    if (expenses.id) {
      return this.httpClient.put<Depenses>(
        environment.apiUrl + 'depenses-projet/' + expenses.id + '/calendrier',
        JSON.stringify(expenses.calendrier),
        {
          observe: 'response',
        }
      );
    } else {
      return this.createExpenses(expenses);
    }
  }

  getExpensesDocument(idProjet: string): Observable<HttpResponse<DocumentProjet[]>> {
    return this.httpClient.get<DocumentProjet[]>(
      environment.apiUrl + 'documents?projetIds=' + idProjet + '&typesDocuments=DEPENSES_PROJET',
      {
        observe: 'response',
      }
    );
  }

  updateExpensesCalendarStatut(expenses: Depenses, statut: EnumValidation): Observable<HttpResponse<Depenses>> {
    const status = {
      statut: statut,
    };

    return this.httpClient.put<Depenses>(
      environment.apiUrl + 'depenses-projet/' + expenses.id + '/calendrier/statut',
      JSON.stringify(status),
      {
        observe: 'response',
      }
    );
  }

  updateExpensesStructureStatut(expenses: Depenses, statut: EnumValidation): Observable<HttpResponse<Depenses>> {
    const status = {
      statut: statut,
    };

    return this.httpClient.put<Depenses>(
      environment.apiUrl + 'depenses-projet/' + expenses.id + '/structure/statut',
      JSON.stringify(status),
      {
        observe: 'response',
      }
    );
  }

  updateExpensesSyntheseStatut(expenses: Depenses, statut: EnumValidation): Observable<HttpResponse<Depenses>> {
    const status = {
      statut: statut,
    };

    return this.httpClient.put<Depenses>(environment.apiUrl + 'depenses-projet/' + expenses.id + '/statut', JSON.stringify(status), {
      observe: 'response',
    });
  }

  postCsvDepenses(idExpenses: string, path: string): Observable<HttpResponse<Depenses>> {
    const body = {
      filePath: path,
    };
    return this.httpClient.post<Depenses>(
      environment.apiUrl + 'depenses-projet/' + idExpenses + '/donnees-budgetaire/csv',
      JSON.stringify(body),
      {
        observe: 'response',
      }
    );
  }

  createExpenses(expenses: Depenses): Observable<HttpResponse<Depenses>> {
    return this.httpClient.put<Depenses>(environment.apiUrl + 'depenses-projet/donnees-budgetaire', JSON.stringify(expenses), {
      observe: 'response',
    });
  }
}

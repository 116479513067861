import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DepensesService } from '@services-candidat/depenses.service';
import { ProjetService } from '@services-candidat/projet.service';
import { UploadDocumentService } from '@services-candidat/upload-document.service';
import { UserService } from '@services-candidat/user.service';
import {
  ConfirmModalComponent,
  Depenses,
  DocumentFileManagerService,
  DocumentHelper,
  DocumentProjet,
  EnumScanDocument,
  EnumScope,
  Projet,
  ShowToastrService,
  SubscriptionDestroyerComponent,
  DepenseProjetUploadErrorModalComponent,
} from '@shared-ui';
import { detect } from 'chardet';
import { Observable, concatMap, from, tap } from 'rxjs';

@Component({
  selector: 'pxl-projet-info-depenses-upload',
  templateUrl: './projet-info-depenses-upload.component.html',
  styleUrls: ['./projet-info-depenses-upload.component.scss'],
})
export class ProjetInfoDepensesUploadComponent extends SubscriptionDestroyerComponent {
  private static readonly ERROR_REGEX = /(.*) sur la ligne (\d+)/g;
  private static readonly MATCH_GROUP_REGEX = /(.*) sur la ligne (\d+)/;

  @Input() projet: Projet = new Projet();
  @Input() expenses: Depenses = new Depenses();
  @Input() documentBdd: DocumentProjet = new DocumentProjet();

  // Event emitter
  @Output() docChanged = new EventEmitter<DocumentProjet>();
  @Output() expensesChanged = new EventEmitter<Depenses>();

  readonly SAFE = EnumScanDocument[EnumScanDocument.SAFE.toString()];
  readonly UNSAFE = EnumScanDocument[EnumScanDocument.UNSAFE.toString()];

  fileToUpload: File;
  maxSize = 20000000;

  path = '';

  constructor(
    public projetService: ProjetService,
    private uploadService: UploadDocumentService,
    private documentFileManagerService: DocumentFileManagerService,
    private showToastrService: ShowToastrService,
    public depensesService: DepensesService,
    public userService: UserService,
    public matDialog: MatDialog
  ) {
    super();
  }

  onSelectDoc(files: FileList): void {
    this.fileToUpload = files.item(0);

    if (this.fileToUpload?.size >= this.maxSize) {
      this.showToastrService.error('Le fichier à importer doit avoir une taille maximale de 10Mo.');
      return;
    }

    if (!['text/csv'].includes(this.fileToUpload.type)) {
      this.showToastrService.error("Le fichier à importer n'est pas au bon format (.csv)");
      return;
    }

    this.uploadDocument(this.fileToUpload);
  }

  getFileArrayBuffer(file: File): Observable<ArrayBuffer> {
    return from(file.arrayBuffer());
  }

  isCSVArrayBufferUTF8(arrayBuffer: ArrayBuffer): boolean {
    return detect(new Uint8Array(arrayBuffer)) === 'UTF-8';
  }

  uploadDocument(file: File) {
    this.getFileArrayBuffer(file)
      .pipe(
        tap((arrayBuffer: ArrayBuffer) => {
          if (!this.isCSVArrayBufferUTF8(arrayBuffer)) {
            throw new HttpErrorResponse({
              error: {
                message:
                  "Une erreur s'est produite lors de l'import. Veuillez vérifier que votre fichier CSV est bien enregistré en .CSV UTF-8",
              },
            });
          }
          this.documentBdd = new DocumentProjet();
          this.documentBdd.nom = file.name;
          this.documentBdd.dateModification = this.projet.dateModification;
          this.documentBdd.projetId = this.projet.id;
          this.documentBdd.typeDoc = 'DEPENSES_PROJET';
          this.documentBdd.scope = EnumScope.PROJET;
        }),
        concatMap(() => this.projetService.upsertDocumentProjet(this.documentBdd)),
        concatMap((rep: HttpResponse<DocumentProjet>) => {
          this.documentBdd = rep.body;
          this.docChanged.emit(this.documentBdd);
          this.path = this.projet.id + '/' + this.documentBdd.id;
          const typeUpload = 'B401';
          return this.uploadService.getValueForDocStructureUpload(
            this.fileToUpload.name,
            this.path,
            typeUpload,
            this.documentBdd.id,
            this.projet.id,
            'DEPENSES_PROJET'
          );
        }),
        concatMap((result: any) => {
          return this.uploadService.upload(result.body.url, this.fileToUpload, this.projet.id, this.documentBdd.id, 'DEPENSES_PROJET');
        }),
        concatMap(() => {
          this.path = this.path + '/' + this.fileToUpload.name;
          return this.depensesService.postCsvDepenses(this.expenses.id, this.path);
        })
      )
      .subscribe({
        next: result => {
          this.showToastrService.success('Le fichier a bien été importé');
          this.docChanged.emit(this.documentBdd);
          this.expensesChanged.emit(result.body);
        },
        error: (err: HttpErrorResponse) => {
          const arrayPath = err?.error?.message?.split(';');
          if (arrayPath && arrayPath?.length > 1) {
            this.openDialogErrorCSV(arrayPath);
          } else {
            this.showToastrService.error(err?.error?.message);
          }
          this.documentBdd = null;
          this.docChanged.emit(this.documentBdd);
        },
      });
  }

  /*
   * Vérifie si le document uploadé a passé le test antivirus
   * */
  isScanedDocument(document: DocumentProjet): boolean {
    if (document?.scan === this.SAFE || document?.scan === this.UNSAFE) {
      return true;
    }
    return false;
  }

  /*
   * Affiche le nom du créateur du Document
   * */
  getNomCreateurDocument(document: DocumentProjet): string {
    return DocumentHelper.getNomCreateurDocument(document);
  }

  /*
   * Download a document
   * */
  downloadDocument(document: DocumentProjet): void {
    this.documentFileManagerService.downloadDocument(document).pipe(this.takeUntilDestroyed()).subscribe();
  }

  /*
   * Affiche la modale pour supprimer un document
   * */
  onDeleteDocument(document: DocumentProjet): void {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        description: `<p>Êtes-vous sûr de vouloir supprimer ce fichier.</p>
                          <p>Cette action est irréversible. </p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Non',
        icon: true,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.projetService.deleteDocument(this.projet.id, document.id).subscribe({
        next: () => {
          this.showToastrService.success('Le fichier a bien été supprimé');
          this.documentBdd = null;
          this.docChanged.emit(this.documentBdd);
          if (document.scan === (EnumScanDocument as any)[EnumScanDocument.UNSAFE.toString()]) {
            return;
          }
          if (document.stockerGed) {
            return;
          }
          const path = this.projet.id + '/' + document.id + '/' + document.nom;
          this.uploadService.deletDocStructureUpload(path, DocumentHelper.TYPE_B401, document.id, this.projet.id).subscribe({
            error: (err: HttpErrorResponse) => {
              this.showToastrService.checkCodeError(err?.error);
            },
          });
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
    });
  }
  private extractErrors(error: string): [string, string][] {
    const matches = error.match(ProjetInfoDepensesUploadComponent.ERROR_REGEX) || [];
    return matches
      .map(match => match.match(ProjetInfoDepensesUploadComponent.MATCH_GROUP_REGEX))
      .filter((match): match is RegExpMatchArray => !!match)
      .map(([, message, line]) => [message, line]);
  }

  private openDialogErrorCSV(arrayPath: string[]) {
    const errorsMap: Record<string, string[]> = {};

    arrayPath
      .map(this.extractErrors)
      .flat()
      .forEach(([message, line]) => {
        errorsMap[message] = errorsMap[message] || [];
        errorsMap[message].push(line);
      });

    const errorsList = Object.entries(errorsMap)
      .map(([message, lines]) => {
        const linesList = lines.map(line => `<li>ligne ${line}</li>`).join('');
        return `<li>${message}<ul>${linesList}</ul></li>`;
      })
      .join('');

    const dialogRef = this.matDialog.open(DepenseProjetUploadErrorModalComponent, {
      data: {
        description: `
      <p>Une ou plusieurs erreurs se sont produites lors de l'import. Veuillez vérifier votre fichier CSV.</p>
      <p>Le fichier contient des erreurs : </p>
      <ul>
        ${errorsList}
      </ul>`,
        textGoButton: 'Ok',
        icon: false,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
    });
  }
}

import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { StructureService } from '@services-candidat/structure.service';
import { catchError, map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { Structure } from '@shared-ui';
import { of } from 'rxjs';

export const structureResolver: ResolveFn<Structure | null> = route => {
  const structureService = inject(StructureService);
  const idStructure = route.paramMap.get('structureId');

  if (idStructure) {
    return structureService.getStructureById(idStructure).pipe(
      map((response: HttpResponse<Structure>) => response.body as Structure),
      catchError(() => {
        return of(null);
      })
    );
  } else {
    return of(null);
  }
};
